<template>
  <div id="app" class="bg-brandBlack min-h-screen text-brandWhite">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/* Ajustes globales */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

#app {
  background-color: #000000;
  color: white;
  min-height: 100vh;
}
</style>
