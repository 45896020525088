<template>
  <div class="checkout-page">
    <header>
      <Header :cart="cart" @toggle-cart="handleCartToggle" />
    </header>

    <main class="centered-container">
      <div class="centered-box">
        <h1>Pasarela de Pago en construcción</h1>
        <p>Contáctanos para más información:</p>
        <p>📧 <a href="mailto:piandpi.publidis@gmail.com">piandpi.publidis@gmail.com</a></p>
        <p>📞 +569 6548 2040</p>
      </div>
    </main>

    <footer>
      <Footer />
    </footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      cart: [],
    };
  },
  methods: {
    handleCartToggle() {
      console.log("Carrito abierto o cerrado");
    },
  },
};
</script>

<style scoped>
.checkout-page {
  background-color: #121212;
  color: #ffffff;
  text-align: center;
  padding: 20px;
}

.centered-container {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  height: calc(100vh - 100px); /* Ajusta para compensar el header y footer */
  padding-bottom: 200px;
}

.centered-box {
  padding: 40px; /* Aumenta el espacio interno */
  border: 3px solid #e63946; /* Borde más grueso */
  border-radius: 12px; /* Bordes redondeados */
  background-color: #1a1a1a; /* Fondo del cuadrado */
  color: #ffffff; /* Color del texto */
  width: 600px; /* Ancho fijo más grande */
  height: 400px; /* Altura fija más grande */
  text-align: center; /* Centrar el texto */
  display: flex; /* Usar flexbox */
  flex-direction: column; /* Alinear verticalmente */
  justify-content: center; /* Centrar el contenido verticalmente */
  align-items: center; /* Centrar el contenido horizontalmente */
}

a {
  color: #e63946;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
