<template>
  <div class="shop-view">
    <!-- Barra de navegación -->
    <div class="relative bg-gray-800">

    <!-- Ícono de Home -->
    <router-link to="/" class="absolute top-4 left-4 flex items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-8 w-8 text-white hover:text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3 9.75L12 3l9 6.75v10.5A2.25 2.25 0 0118.75 21h-13.5A2.25 2.25 0 013 20.25V9.75z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9 22V12h6v10"
        />
      </svg>
    </router-link>

  <!-- Imagen de portada a lo largo -->
  <img
    src="/images/pinpi_portada.jpeg"
    alt="Portada"
    class="w-full h-48 object-cover"
  />

  <!-- Botón del carrito superpuesto -->
  <div class="absolute top-4 right-4">
    <button @click="toggleCart" class="relative">
      <img
        src="/images/cart-icon-white.png"
        alt="Carrito"
        class="h-8 w-8 object-contain"
      />
      <span
        v-if="cart.length > 0"
        class="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center"
      >
        {{ cart.length }}
      </span>
    </button>
  </div>
</div>

    <!-- Categorías -->
    <div class="flex justify-center bg-graphite p-2">
      <button
        v-for="category in categories"
        :key="category"
        @click="filterCategory(category)"
        class="mx-2 text-white py-1 px-3 rounded hover:bg-red-700"
        :class="{ 'bg-red-600': activeCategory === category }"
      >
        {{ category }}
      </button>
    </div>

    <!-- Lista de productos -->
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 p-4 max-w-7xl mx-auto">
      <div
        v-for="product in filteredProducts"
        :key="product.id"
        class="border border-darkGray bg-graphite rounded-lg p-4 text-center text-lightGray"
      >
        <img
          :src="product.image"
          alt="Product Image"
          class="w-full h-40 object-cover mb-4"
        />
        <h3 class="font-bold text-lg">{{ product.name }}</h3>
        <p class="text-white font-bold text-xl">{{ product.price }}</p>
        <div class="mt-4 flex items-center justify-between space-x-4">
          <select
            v-model="product.selectedSize"
            class="bg-darkGray text-white p-2 rounded w-1/2"
          >
            <option v-for="size in product.sizes" :key="size" :value="size">
              {{ size }}
            </option>
          </select>
          <input
            type="number"
            min="1"
            v-model.number="product.quantity"
            class="bg-darkGray text-white p-2 rounded w-1/3 text-center"
          />
        </div>
        <button
          @click="addToCart(product)"
          class="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4 w-full"
        >
          Agregar al carrito
        </button>
      </div>
    </div>

    <!-- Carrito -->
    <div
      v-if="showCart"
      class="absolute right-4 top-16 bg-graphite text-white shadow-lg rounded-lg p-4 w-64 z-50"
    >
      <h2 class="text-lg font-bold mb-4">Carrito de Compras</h2>
      <div v-for="item in cart" :key="item.id" class="mb-4">
      <h3 class="font-bold">{{ item.name }}</h3>
      <p>Talla: {{ item.selectedSize }}</p>
      <input
        type="number"
        v-model.number="item.quantity"
        class="bg-darkGray text-white p-2 rounded w-1/3 text-center"
      />
      <p>Subtotal: {{ (item.price * item.quantity).toFixed(2) }}</p>
    </div>
  <div class="border-t border-gray-700 mt-4 pt-4">
    <p class="text-lg font-bold">Total: {{ cartTotal.toFixed(2) }}</p>
  </div>
  <button
    @click="clearCart"
    class="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-full mt-4"
  >
    Vaciar Carrito
  </button>
  <router-link to="/checkout">
    <button
      class="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full mt-4"
    >
      Continuar
    </button>
  </router-link>
</div>


  </div>
</template>

<script>
export default {
  name: "ShopView",
  data() {
    return {
      categories: ["Todos", "Poleras", "Tazas", "Gorros", "Polerones","Lanyard"],
      activeCategory: "Todos",
      products: [
        {
          id: 1,
          name: "Polera Personalizada",
          price: 17000,
          image: "images/polera.jpeg",
          sizes: ["XS", "S", "M", "L","XL"],
          selectedSize: "",
          quantity: 1,
          category: "Poleras",
        },
        {
          id: 2,
          name: "Taza Exclusiva",
          price: 3000,
          image: "images/tazon.jpeg",
          sizes: ["Única"],
          selectedSize: "Única",
          quantity: 1,
          category: "Tazas",
        },
        {
          id: 3,
          name: "Lanyard Personalizado",
          price: 3000,
          image: "images/lanyard.jpeg",
          sizes: ["Única"],
          selectedSize: "Única",
          quantity: 1,
          category: "Lanyard",
        },
        {
          id: 4,
          name: "Gorro Cerrado",
          price: 3500,
          image: "images/gorro_1.jpeg",
          sizes: ["Única"],
          selectedSize: "Única",
          quantity: 1,
          category: "Gorros",
        },
        {
          id: 5,
          name: "Gorro Malla",
          price: 3500,
          image: "images/gorro_2.jpeg",
          sizes: ["Única"],
          selectedSize: "Única",
          quantity: 1,
          category: "Gorros",
        },
        {
          id: 4,
          name: "Gorro Cerrado",
          price: 3500,
          image: "images/gorro_3.jpeg",
          sizes: ["Única"],
          selectedSize: "Única",
          quantity: 1,
          category: "Gorros",
        },
        {
          id: 5,
          name: "Gorro Cerrado",
          price: 3500,
          image: "images/gorro_4.jpeg",
          sizes: ["Única"],
          selectedSize: "Única",
          quantity: 1,
          category: "Gorros",
        },
        {
          id: 6,
          name: "Gorro Cerrado",
          price: 3500,
          image: "images/gorro_5.jpeg",
          sizes: ["Única"],
          selectedSize: "Única",
          quantity: 1,
          category: "Gorros",
        },
        {
          id: 7,
          name: "Gorro Malla",
          price: 3500,
          image: "images/gorro_6.jpeg",
          sizes: ["Única"],
          selectedSize: "Única",
          quantity: 1,
          category: "Gorros",
        },

      ],
      cart: [],
      showCart: false,
    };
  },
  computed: {
    filteredProducts() {
      if (this.activeCategory === "Todos") {
        return this.products;
      }
      return this.products.filter((p) => p.category === this.activeCategory);
    },
    cartTotal() {
      return this.cart.reduce((total,item) => total + item.price * item.quantity, 0);
    }
  },
  methods: {
    filterCategory(category) {
      this.activeCategory = category;
    },
    toggleCart() {
      this.showCart = !this.showCart;
    },
    addToCart(product) {
      const existing = this.cart.find(
        (item) => item.id === product.id && item.selectedSize === product.selectedSize
      );
      if (existing) {
        existing.quantity += product.quantity;
      } else {
        this.cart.push({ ...product });
      }
      product.quantity = 1;
    },
    clearCart() {
      this.cart = [];
    },
  },
};
</script>

<style scoped>
.shop-view {
  position: relative;
}
</style>