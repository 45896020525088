<template>
  <div
    id="home"
    class="relative h-screen bg-cover bg-center text-center flex flex-col items-center justify-center"
    style="background-image: url('/images/pinpi.webp');"
  >
    <div class="absolute inset-0 bg-black opacity-50"></div>
    <div class="relative z-10 text-white">
      <h1 class="text-5xl font-bold mb-4">PI AN PI</h1>
      <p class="text-xl mb-6">Descubre nuestra colección única de productos personalizados.</p>
      <router-link to="/shop">
        <button class="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-6 rounded-lg">
          Ir a la tienda
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
};
</script>

<style scoped>
#home {
  background-size: cover;
  background-position: center;
}
</style>
