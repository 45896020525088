<template>
    <footer class="footer">
      <p>© 2024 Pi and Pi. Todos los derechos reservados.</p>
    </footer>
  </template>
  
  <script>
  export default {
    name: "Footer",
  };
  </script>
  
  <style scoped>
  .footer {
    background-color: #121212;
    color: #ffffff;
    padding: 10px;
    text-align: center;
  }
  </style>
  